import { Close } from '@mui/icons-material';
import { Box, DialogProps, Dialog as MuiDialog } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from './button';

export function Dialog({
  disableBackgroundClick,
  disableCloseButton,
  disablePadding,
  backgroundColor,
  children,
  loading,
  onClose,
  ...props
}: Omit<DialogProps, 'onClose'> & {
  backgroundColor?: string;
  disableBackgroundClick?: boolean;
  disableCloseButton?: boolean;
  disablePadding?: boolean;
  loading?: boolean;
  onClose: () => void;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <MuiDialog
      {...props}
      onClose={(_, reason) =>
        !loading && (!disableBackgroundClick || reason !== 'backdropClick') && onClose()
      }
      PaperProps={{
        ...props.PaperProps,
        elevation: 0,
        sx: { backgroundColor: 'transparent', width: 'calc(100% - 32px)', ...props.PaperProps?.sx },
      }}
      scroll="body"
    >
      {!disableCloseButton && (
        <Box display="flex" flexDirection="column" gap={1}>
          <Box
            alignItems="center"
            display="flex"
            height={32}
            justifyContent="flex-end"
            sx={{ color: '#fff' }}
          >
            <Button
              color="inherit"
              disabled={loading}
              endIcon={<Close fontSize="small" />}
              onClick={() => onClose()}
              size="small"
            >
              {t('commons.actions.close')}
            </Button>
          </Box>
        </Box>
      )}
      <Box
        borderRadius={4}
        display="flex"
        flexDirection="column"
        padding={disablePadding ? 0 : 5}
        position="relative"
        sx={{ backgroundColor: backgroundColor || '#fff', overflow: 'hidden' }}
      >
        {children}
      </Box>
    </MuiDialog>
  );
}
