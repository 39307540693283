import { badgeUrls, useAmplitudeTracker } from '@geovelo-frontends/commons';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';
import { environment } from '../../environment';

export function GooglePlayBadge({ height }: { height: number }): JSX.Element {
  const {
    partner: { current: currentPartner, contract },
    user: { employee },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { trackEvent } = useAmplitudeTracker();

  return (
    <Box
      component="a"
      display="inline-block"
      height={height}
      href={`${environment.android.storeUrls.fr}&referrer=utm_source%3Dwebsite`}
      onClick={() => {
        trackEvent('Google Play Badge Clicked', {
          employee_id: employee?.id,
          partner_id: currentPartner?.id,
          partner_code: currentPartner?.code,
          contract_type: contract?.contractTemplate.code,
        });
      }}
      overflow="hidden"
      rel="noreferrer"
      target="_blank"
    >
      <img
        alt={t('commons.app_badge_labels.google_play') || ''}
        height={height}
        src={badgeUrls.fr.android}
        width="auto"
      />
    </Box>
  );
}
