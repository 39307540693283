import { SvgIcon, SvgIconProps } from '@mui/material';

export function UnlockIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 23 23" {...props}>
      <path
        d="M16.4714 8.74968H9.13802V6.91635C9.13668 6.37202 9.29692 5.83954 9.59843 5.38634C9.89993 4.93315 10.3292 4.57962 10.8317 4.37055C11.3343 4.16147 11.8876 4.10624 12.4216 4.21186C12.9556 4.31748 13.4462 4.57919 13.8314 4.96385C14.176 5.31591 14.4225 5.75202 14.5464 6.22885C14.5764 6.34562 14.6292 6.45531 14.7017 6.55167C14.7742 6.64804 14.865 6.72918 14.9688 6.79046C15.0727 6.85175 15.1876 6.89198 15.307 6.90886C15.4264 6.92574 15.5479 6.91894 15.6647 6.88885C15.7815 6.85875 15.8912 6.80595 15.9875 6.73347C16.0839 6.66098 16.165 6.57022 16.2263 6.46637C16.2876 6.36253 16.3278 6.24762 16.3447 6.12823C16.3616 6.00883 16.3548 5.88728 16.3247 5.77052C16.1159 4.97742 15.7017 4.25337 15.1239 3.67135C14.4824 3.03185 13.6657 2.59677 12.7771 2.42106C11.8886 2.24534 10.9678 2.33686 10.1312 2.68406C9.29457 3.03126 8.57959 3.61858 8.07653 4.37184C7.57346 5.12511 7.30488 6.01054 7.30469 6.91635V8.74968C6.57534 8.74968 5.87587 9.03941 5.36014 9.55514C4.84442 10.0709 4.55469 10.7703 4.55469 11.4997V17.9163C4.55469 18.6457 4.84442 19.3452 5.36014 19.8609C5.87587 20.3766 6.57534 20.6663 7.30469 20.6663H16.4714C17.2007 20.6663 17.9002 20.3766 18.4159 19.8609C18.9316 19.3452 19.2214 18.6457 19.2214 17.9163V11.4997C19.2214 10.7703 18.9316 10.0709 18.4159 9.55514C17.9002 9.03941 17.2007 8.74968 16.4714 8.74968ZM17.388 17.9163C17.388 18.1595 17.2914 18.3926 17.1195 18.5645C16.9476 18.7364 16.7145 18.833 16.4714 18.833H7.30469C7.06157 18.833 6.82841 18.7364 6.65651 18.5645C6.4846 18.3926 6.38802 18.1595 6.38802 17.9163V11.4997C6.38802 11.2566 6.4846 11.0234 6.65651 10.8515C6.82841 10.6796 7.06157 10.583 7.30469 10.583H16.4714C16.7145 10.583 16.9476 10.6796 17.1195 10.8515C17.2914 11.0234 17.388 11.2566 17.388 11.4997V17.9163Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
