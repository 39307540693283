import { SvgIcon, SvgIconProps } from '@mui/material';

export function RemoveIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 14 14" {...props}>
      <path
        d="M8.52002 3.625V4.125H9.02002H11.27C11.2866 4.125 11.3025 4.13159 11.3142 4.14331C11.3259 4.15503 11.3325 4.17092 11.3325 4.1875C11.3325 4.20408 11.3259 4.21997 11.3142 4.23169C11.3025 4.24341 11.2866 4.25 11.27 4.25H10.7075H10.2075V4.75V10.9375C10.2075 11.2524 10.0824 11.5545 9.85971 11.7772C9.63701 11.9999 9.33496 12.125 9.02002 12.125H4.52002C4.20507 12.125 3.90303 11.9999 3.68033 11.7772C3.45763 11.5545 3.33252 11.2524 3.33252 10.9375V4.75V4.25H2.83252H2.27002C2.25344 4.25 2.23755 4.24342 2.22583 4.23169C2.2141 4.21997 2.20752 4.20408 2.20752 4.1875C2.20752 4.17092 2.2141 4.15503 2.22583 4.14331C2.23755 4.13158 2.25344 4.125 2.27002 4.125H4.52002H5.02002V3.625V3.0625C5.02002 2.74756 5.14513 2.44551 5.36783 2.22281C5.59053 2.00011 5.89258 1.875 6.20752 1.875H7.33252C7.64746 1.875 7.94951 2.00011 8.17221 2.22281C8.39491 2.44551 8.52002 2.74756 8.52002 3.0625V3.625ZM7.89502 4.125H8.39502V3.625V3.0625C8.39502 2.78071 8.28308 2.51046 8.08382 2.3112C7.88456 2.11194 7.61431 2 7.33252 2H6.20752C5.92573 2 5.65548 2.11194 5.45622 2.3112C5.25696 2.51046 5.14502 2.78071 5.14502 3.0625V3.625V4.125H5.64502H7.89502ZM3.95752 4.25H3.45752V4.75V10.9375C3.45752 11.2193 3.56946 11.4895 3.76872 11.6888C3.96798 11.8881 4.23823 12 4.52002 12H9.02002C9.30181 12 9.57206 11.8881 9.77132 11.6888C9.97058 11.4895 10.0825 11.2193 10.0825 10.9375V4.75V4.25H9.58252H3.95752Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </SvgIcon>
  );
}
