import { EmployeeService } from '@geovelo-frontends/commons';
import { useContext, useEffect } from 'react';

import { AppContext } from '../app/context';

function useOfferDialog(open: boolean, surveyAnswerKey: string | null) {
  const {
    partner: { current: currentPartner },
    user: { employee },
    actions: { setUserEmployee },
  } = useContext(AppContext);

  useEffect(() => {
    if (open && surveyAnswerKey && employee) {
      if (!employee.surveyAnswers[surveyAnswerKey]) handleSeen();
    }
  }, [open, employee]);

  async function handleSeen() {
    if (!currentPartner || !employee || !surveyAnswerKey) return;

    try {
      const updatedEmployee = await EmployeeService.updateEmployee(currentPartner, employee.id, {
        surveyAnswers: { ...employee.surveyAnswers, [surveyAnswerKey]: true },
      });

      setUserEmployee(updatedEmployee);
    } catch (err) {
      console.error(err);
    }
  }
}

export default useOfferDialog;
