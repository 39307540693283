import { PartnerService } from '@geovelo-frontends/commons';
import { Add, Close } from '@mui/icons-material';
import { Box, Chip, CircularProgress, DialogProps, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';

import { AppContext } from '../../../app/context';
import { Button, Dialog } from '../../../components';

function RestrictAccessDialog({
  ...props
}: Omit<DialogProps, 'onClose'> & {
  onClose: (reload?: boolean) => void;
}): JSX.Element {
  const [domainNames, setDomainNames] = useState<string[]>([]);
  const [newDomainName, setNewDomainName] = useState('');
  const [error, setError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    partner: { current: currentPartner },
    actions: { setCurrentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.open) {
      setNewDomainName('');
      setDomainNames(
        !currentPartner?.whiteListedDomains || currentPartner.whiteListedDomains.length === 0
          ? []
          : [...currentPartner.whiteListedDomains],
      );
      setError(false);
    }
  }, [props.open]);

  async function handleSubmit() {
    if (!currentPartner) return;

    let newDomainNames = [...domainNames];

    if (newDomainName) {
      if (
        validator.isURL(newDomainName, {
          require_host: false,
          allow_query_components: false,
          disallow_auth: true,
        })
      ) {
        newDomainNames = [...domainNames, newDomainName];
      } else {
        setError(true);
        return;
      }
    }

    setError(false);
    setSubmitting(true);

    try {
      const partner = await PartnerService.editPartner(currentPartner, {
        whiteListedDomains: [...new Set(newDomainNames.filter(Boolean))],
      });

      enqueueSnackbar(t('companies.pages.admin.company.employees.restrict_access_dialog.success'));
      setCurrentPartner(partner);
      props.onClose();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        t('companies.pages.admin.company.employees.restrict_access_dialog.server_error'),
        { variant: 'error' },
      );
    }

    setSubmitting(false);
  }

  return (
    <Dialog fullWidth loading={isSubmitting} maxWidth="sm" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontSize="1.25rem" fontWeight={600}>
          {t('companies.pages.admin.company.employees.restrict_access_dialog.title')}
        </Typography>
        <Typography>
          {t('companies.pages.admin.company.employees.restrict_access_dialog.info', {
            geogroupTitle: currentPartner?.title,
          })}
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography fontWeight={700}>
            {t(
              'companies.pages.admin.company.employees.restrict_access_dialog.authorized_domain_names',
            )}
          </Typography>
          {domainNames.length > 0 && (
            <Box display="flex" flexWrap="wrap" gap={1}>
              {domainNames.map((domainName, index) => (
                <Chip
                  color="primary"
                  key={`${domainName}-${index}`}
                  label={domainName}
                  onDelete={() => {
                    const newDomainNames = [...domainNames];
                    newDomainNames.splice(index, 1);
                    setDomainNames(newDomainNames);
                  }}
                />
              ))}
            </Box>
          )}
          <Box alignItems="center" display="flex" gap={2}>
            <TextField
              fullWidth
              error={error}
              InputLabelProps={{ shrink: true }}
              onChange={({ currentTarget }) => {
                setNewDomainName(currentTarget.value);
              }}
              onKeyDown={({ key }) => {
                if (
                  key === 'Enter' &&
                  newDomainName &&
                  validator.isURL(newDomainName, {
                    require_host: false,
                    allow_query_components: false,
                    disallow_auth: true,
                  })
                ) {
                  setDomainNames([...domainNames, newDomainName]);
                  setNewDomainName('');
                  setError(false);
                } else if (key === 'Enter') {
                  setError(true);
                }
              }}
              placeholder={t(
                'companies.pages.admin.company.employees.restrict_access_dialog.authorized_domain_name_placeholder',
              )}
              size="small"
              value={newDomainName}
              variant="outlined"
            />
            <Button
              color="primary"
              disabled={
                !newDomainName ||
                !validator.isURL(newDomainName, {
                  require_host: false,
                  allow_query_components: false,
                  disallow_auth: true,
                })
              }
              onClick={() => {
                setDomainNames([...domainNames, newDomainName]);
                setNewDomainName('');
                setError(false);
              }}
              startIcon={<Add />}
              variant="contained"
            >
              {t('commons.actions.add')}
            </Button>
          </Box>
        </Box>
        <Box alignItems="center" display="flex" gap={3} justifyContent="flex-end">
          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={() => props.onClose()}
            startIcon={<Close />}
            variant="outlined"
          >
            {t('commons.actions.cancel')}
          </Button>
          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={handleSubmit}
            startIcon={isSubmitting && <CircularProgress color="inherit" size={16} thickness={4} />}
            variant="contained"
          >
            {t('commons.actions.validate')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default RestrictAccessDialog;
