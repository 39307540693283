import { SvgIcon, SvgIconProps } from '@mui/material';

export function FilterIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M5.21884 6.40875C7.24884 9.01625 10.2501 12.875 10.2501 12.875V17.25C10.2501 18.2125 11.0376 19 12.0001 19C12.9626 19 13.7501 18.2125 13.7501 17.25V12.875C13.7501 12.875 16.7513 9.01625 18.7813 6.40875C19.2276 5.83125 18.8163 5 18.0813 5H5.91009C5.18384 5 4.77259 5.83125 5.21884 6.40875Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
}
