import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

function LoadingPage({ text, ...props }: { text: React.ReactNode }): JSX.Element {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap={2}
      height="calc(100% - 32px)"
      justifyContent="center"
      padding={2}
      {...props}
    >
      <CircularProgress color="primary" />
      <Typography>{text}</Typography>
    </Box>
  );
}

export default LoadingPage;
