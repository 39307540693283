import { Box } from '@mui/material';

import { PremiumIcon } from './icons';

export function PremiumAvatar({ size }: { size?: 'small' }): JSX.Element {
  return (
    <Box
      alignItems="center"
      bgcolor="#EAE2FC"
      borderRadius={size === 'small' ? 1 : 2}
      display="inline-flex"
      height={size === 'small' ? 18 : 24}
      justifyContent="center"
      width={size === 'small' ? 18 : 24}
    >
      <PremiumIcon sx={{ color: '#6236BD', height: 10, width: 10 }} />
    </Box>
  );
}
