import { SvgIcon, SvgIconProps } from '@mui/material';

export function LightbulbIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M17.0899 2.81934C16.1702 2.06292 15.0933 1.52137 13.9376 1.23418C12.7819 0.946995 11.5767 0.921425 10.4099 1.15934C8.85632 1.47316 7.43093 2.24141 6.31462 3.36662C5.19832 4.49182 4.44141 5.92327 4.13994 7.47934C3.91979 8.64606 3.95979 9.84698 4.2571 10.9965C4.55441 12.146 5.1017 13.2157 5.85994 14.1293C6.56363 14.9234 6.96698 15.9389 6.99994 16.9993V19.9993C6.99994 20.795 7.31601 21.558 7.87862 22.1207C8.44123 22.6833 9.20429 22.9993 9.99994 22.9993H13.9999C14.7956 22.9993 15.5587 22.6833 16.1213 22.1207C16.6839 21.558 16.9999 20.795 16.9999 19.9993V17.1893C17.0335 16.0185 17.4637 14.8938 18.2199 13.9993C19.5451 12.3601 20.1697 10.2642 19.9581 8.16696C19.7465 6.06977 18.7158 4.14082 17.0899 2.79934V2.81934ZM14.9999 19.9993C14.9999 20.2646 14.8946 20.5189 14.707 20.7064C14.5195 20.894 14.2652 20.9993 13.9999 20.9993H9.99994C9.73473 20.9993 9.48037 20.894 9.29284 20.7064C9.1053 20.5189 8.99994 20.2646 8.99994 19.9993V18.9993H14.9999V19.9993ZM16.6699 12.7593C15.6644 13.9519 15.0778 15.4414 14.9999 16.9993H12.9999V13.9993C12.9999 13.7341 12.8946 13.4798 12.707 13.2922C12.5195 13.1047 12.2652 12.9993 11.9999 12.9993C11.7347 12.9993 11.4804 13.1047 11.2928 13.2922C11.1053 13.4798 10.9999 13.7341 10.9999 13.9993V16.9993H8.99994C8.97356 15.4674 8.40689 13.9941 7.39994 12.8393C6.73558 12.0433 6.28883 11.0888 6.10325 10.0687C5.91767 9.04861 5.99959 7.99785 6.34103 7.01887C6.68247 6.03988 7.27176 5.16608 8.05146 4.48266C8.83116 3.79924 9.77465 3.32954 10.7899 3.11934C11.6625 2.93968 12.5641 2.95658 13.4293 3.16881C14.2945 3.38103 15.1016 3.78326 15.792 4.34629C16.4824 4.90932 17.0387 5.61901 17.4206 6.42385C17.8025 7.22869 18.0004 8.10848 17.9999 8.99934C18.0073 10.3692 17.537 11.6988 16.6699 12.7593Z"
        fill="currentColor"
        id="Vector"
      />
    </SvgIcon>
  );
}
