import { SvgIcon, SvgIconProps } from '@mui/material';

export function TripIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 21 21" {...props}>
      <path
        d="M14.1111 1.86956H8.61864C8.08034 1.86956 7.64396 2.30594 7.64396 2.84424C7.64396 3.38254 8.08034 3.81891 8.61864 3.81891H14.1111C15.4158 3.81891 16.5693 4.91605 16.5693 6.29655C16.5693 7.72947 15.5339 8.77003 14.2731 8.77003H7.51221C5.08405 8.77003 3.26019 10.808 3.26019 13.1915C3.26019 15.579 5.08394 17.6185 7.51221 17.6185H11.9243C12.4619 17.6185 12.8976 17.1827 12.8976 16.6452C12.8976 16.1076 12.4619 15.6719 11.9243 15.6719H7.51221C6.13969 15.6719 5.14031 14.5069 5.14031 13.1915C5.14031 11.8843 6.13976 10.7139 7.51221 10.7139H14.2717C16.5791 10.7139 18.4454 8.79198 18.4454 6.29516C18.4454 3.83312 16.4625 1.86956 14.1111 1.86956Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.221816"
      />
      <path
        d="M4.55576 2.997C4.55576 3.58247 4.08027 4.05576 3.5 4.05576C2.91973 4.05576 2.44424 3.58247 2.44424 2.997C2.44424 2.41846 2.9168 1.94424 3.5 1.94424C4.0832 1.94424 4.55576 2.41846 4.55576 2.997ZM3.5 5.61091C4.94034 5.61091 6.11091 4.44017 6.11091 2.997C6.11091 1.5576 4.94011 0.389092 3.5 0.389092C2.05989 0.389092 0.889092 1.5576 0.889092 2.997C0.889092 4.44017 2.05966 5.61091 3.5 5.61091Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.221816"
      />
      <path
        d="M17.0859 13.0703C16.3996 13.0703 15.7413 13.343 15.256 13.8283C14.7707 14.3136 14.498 14.9718 14.498 15.6582C14.498 17.405 16.7786 19.3782 16.8756 19.4623C16.9342 19.5124 17.0088 19.54 17.0859 19.54C17.163 19.54 17.2376 19.5124 17.2962 19.4623C17.4094 19.3782 19.6737 17.405 19.6737 15.6582C19.6737 14.9718 19.4011 14.3136 18.9158 13.8283C18.4305 13.343 17.7722 13.0703 17.0859 13.0703ZM17.0859 18.7798C16.3969 18.1328 15.145 16.7386 15.145 15.6582C15.145 15.1434 15.3495 14.6497 15.7135 14.2857C16.0775 13.9218 16.5711 13.7173 17.0859 13.7173C17.6007 13.7173 18.0943 13.9218 18.4583 14.2857C18.8223 14.6497 19.0268 15.1434 19.0268 15.6582C19.0268 16.7386 17.7749 18.136 17.0859 18.7798Z"
        fill="black"
        stroke="currentColor"
        strokeWidth="1.10908"
      />
    </SvgIcon>
  );
}
