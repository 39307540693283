import { G, Path, Svg } from '@react-pdf/renderer';

function FooterBackground(): JSX.Element {
  return (
    <Svg viewBox="0 0 420 134" width="100%">
      <G opacity="0.8">
        <Path
          d="M147.051 118.876C156.094 129.414 200.785 145.35 222 152L-30 152L-30 47C-18.7372 49.3021 7.91672 59.4312 24.43 81.5311C45.0717 109.156 83.4061 98.7966 103.311 99.5639C123.215 100.331 135.747 105.703 147.051 118.876Z"
          fill="#A7C4F4"
        />
        <Path
          d="M171.612 174.262C190.377 164.828 244.794 174.893 256.895 177.512L172.898 230.868L-139.597 97.8423C-133.222 100.479 -180.844 -3.58521 -150.692 1.07078C-112.968 6.89615 -78.3479 22.9794 -59.7834 44.0968C-29.1741 78.9154 11.7148 83.9361 46.4362 83.1567C77.8196 82.4523 80.9441 90.3926 107.558 112.493C134.171 134.592 158.362 180.924 171.612 174.262Z"
          fill="#A3F1CF"
          opacity="0.8"
        />
      </G>
    </Svg>
  );
}

export default FooterBackground;
