import { ButtonProps, Button as MuiButton } from '@mui/material';
import { Link } from 'react-router-dom';

export type TButtonProps = ButtonProps & {
  component?: 'a' | typeof Link;
  rel?: string;
  state?: object;
  target?: '_blank';
  to?: string;
};

export function Button({ sx, ...props }: TButtonProps): JSX.Element {
  return (
    <MuiButton
      disableElevation
      size="large"
      {...props}
      sx={{ textTransform: 'initial', borderRadius: 2, ...sx }}
    />
  );
}
