import { PartnerService } from '@geovelo-frontends/commons';
import { Box, CircularProgress, DialogProps, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';
import { Button, Dialog } from '../../components';
import SizeRangeForm, { sizeRanges } from '../../pages/admin/data/size-range-form';

function CompanySizeDialog({
  ...props
}: Omit<DialogProps, 'onClose'> & { onClose: () => void }): JSX.Element {
  const [sizeRangeIndex, setSizeRangeIndex] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    partner: { current: currentPartner },
    actions: { setCurrentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  async function handleSubmit() {
    if (!currentPartner) return;

    setSubmitting(true);

    const companyMaxSize = sizeRanges[sizeRangeIndex].max || 100_000_000;

    try {
      await PartnerService.editPartner(currentPartner, { companyMaxSize });

      if (currentPartner) {
        currentPartner.companyMaxSize = companyMaxSize;
        setCurrentPartner(currentPartner.clone());
      }

      props.onClose();
    } catch (err) {
      enqueueSnackbar(t('companies.size_dialog.server_error'), { variant: 'error' });
    }

    setSubmitting(false);
  }

  return (
    <Dialog disableBackgroundClick disableCloseButton fullWidth maxWidth="md" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontSize="1.25rem" fontWeight={600}>
          {t('companies.size_dialog.title')}
        </Typography>
        <Typography variant="body2">{t('companies.size_dialog.description')}</Typography>
        <SizeRangeForm
          disabled={isSubmitting}
          setSizeRangeIndex={setSizeRangeIndex}
          sizeRangeIndex={sizeRangeIndex}
        />
        <Box alignItems="center" display="flex" gap={3} justifyContent="flex-end">
          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={handleSubmit}
            startIcon={isSubmitting && <CircularProgress color="inherit" size={16} thickness={4} />}
            variant="contained"
          >
            OK
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default CompanySizeDialog;
