import { SvgIcon, SvgIconProps } from '@mui/material';

export function CyclabilityIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 25" {...props}>
      <path
        d="M16.112 4.37017H10.6195C10.0812 4.37017 9.64484 4.80655 9.64484 5.34485C9.64484 5.88315 10.0812 6.31952 10.6195 6.31952H16.112C17.4167 6.31952 18.5702 7.41666 18.5702 8.79716C18.5702 10.2301 17.5348 11.2706 16.2739 11.2706H9.5131C7.08493 11.2706 5.26107 13.3086 5.26107 15.6921C5.26107 18.0797 7.08482 20.1191 9.5131 20.1191H13.9252C14.4628 20.1191 14.8985 19.6833 14.8985 19.1458C14.8985 18.6083 14.4628 18.1725 13.9252 18.1725H9.5131C8.14057 18.1725 7.14119 17.0075 7.14119 15.6921C7.14119 14.3849 8.14065 13.2145 9.5131 13.2145H16.2726C18.58 13.2145 20.4463 11.2926 20.4463 8.79577C20.4463 6.33373 18.4633 4.37017 16.112 4.37017Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.221816"
      />
      <path
        d="M6.55576 5.497C6.55576 6.08247 6.08027 6.55576 5.5 6.55576C4.91973 6.55576 4.44424 6.08247 4.44424 5.497C4.44424 4.91846 4.9168 4.44424 5.5 4.44424C6.0832 4.44424 6.55576 4.91846 6.55576 5.497ZM5.5 8.11091C6.94034 8.11091 8.11091 6.94017 8.11091 5.497C8.11091 4.0576 6.94011 2.88909 5.5 2.88909C4.05989 2.88909 2.88909 4.0576 2.88909 5.497C2.88909 6.94017 4.05966 8.11091 5.5 8.11091Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.221816"
      />
      <path
        d="M19.085 15.5718C18.3987 15.5718 17.7405 15.8445 17.2552 16.3298C16.7698 16.8151 16.4972 17.4733 16.4972 18.1597C16.4972 19.9065 18.7777 21.8797 18.8748 21.9638C18.9334 22.0139 19.0079 22.0415 19.085 22.0415C19.1621 22.0415 19.2367 22.0139 19.2953 21.9638C19.4085 21.8797 21.6729 19.9065 21.6729 18.1597C21.6729 17.4733 21.4002 16.8151 20.9149 16.3298C20.4296 15.8445 19.7714 15.5718 19.085 15.5718ZM19.085 21.2813C18.396 20.6343 17.1442 19.2401 17.1442 18.1597C17.1442 17.6449 17.3486 17.1513 17.7126 16.7873C18.0766 16.4233 18.5703 16.2188 19.085 16.2188C19.5998 16.2188 20.0935 16.4233 20.4575 16.7873C20.8214 17.1513 21.0259 17.6449 21.0259 18.1597C21.0259 19.2401 19.7741 20.6376 19.085 21.2813Z"
        fill="black"
        stroke="currentColor"
        strokeWidth="1.10908"
      />
    </SvgIcon>
  );
}
