import { Box, DialogProps, Typography } from '@mui/material';
import { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../app/context';
import illustration from '../../assets/img/hello-2-illus.svg';
import { Button, Dialog, PremiumAvatar } from '../../components';
import SeeOffersButton from '../../components/see-offers-button';

function WelcomeDialog({
  ...props
}: Omit<DialogProps, 'onClose'> & { onClose: () => void }): JSX.Element {
  const {
    partner: { permissions, getStartedProgression: getStartedAdminProgression },
    user: { employee, getStartedProgression: getStartedEmployeeProgression },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Dialog fullWidth maxWidth="sm" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <img src={illustration} width={160} />
        <Typography fontSize="1.5rem" fontWeight={700}>
          Bienvenue sur Geovelo Entreprise 🎉
        </Typography>
        <Typography component="ul" display="flex" flexDirection="column" gap={2} paddingLeft={3}>
          {employee?.isAdminOrAnimator ? (
            <>
              <Item>Lancez des challenges vélo et suivez les classements</Item>
              <Item>Visualisez les statistiques vélo des trajets domicile-travail</Item>
              {employee.isAdmin && (
                <>
                  <Item isPremium={!permissions.fmdEnabled}>
                    Lancez le Forfait Mobilités Durables vélo
                  </Item>
                  <Item isPremium={!permissions.mobilitySurveyResponsesReadEnabled}>
                    Lancez une enquête mobilité pour comprendre les déplacements domicile-travail de
                    vos collaborateur·ice·s
                  </Item>
                </>
              )}
            </>
          ) : (
            <>
              <Item>Partipez à des challenges avec vos collègues</Item>
              <Item>Hissez-vous dans les tops des classements</Item>
              <Item>
                Suivez les statistiques de trajets vélo domicile-travail et celles de votre
                entreprise
              </Item>
            </>
          )}
        </Typography>
        <Box alignItems="center" display="flex" gap={3} justifyContent="flex-end">
          {employee?.isAdmin && (
            <SeeOffersButton
              context="Welcome Dialog"
              onClick={() => props.onClose()}
              variant="outlined"
            />
          )}
          <Button
            color="primary"
            onClick={() => {
              props.onClose();
              if (
                employee?.isAdminOrAnimator
                  ? getStartedAdminProgression && !getStartedAdminProgression.allDone
                  : getStartedEmployeeProgression && !getStartedEmployeeProgression.allDone
              ) {
                navigate('/get-started');
              }
            }}
            variant="contained"
          >
            {t('companies.actions.here_we_go')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

function Item({ isPremium, children }: { children: ReactNode; isPremium?: boolean }): JSX.Element {
  return (
    <li style={{ lineHeight: '24px' }}>
      {children}
      {isPremium && (
        <Box display="inline-block" marginLeft={1}>
          <PremiumAvatar />
        </Box>
      )}
    </li>
  );
}

export default WelcomeDialog;
