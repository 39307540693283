import { HttpService, UserService } from '@geovelo-frontends/commons';
import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';

import { AppContext } from '../app/context';

function WebviewLayout(): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const [searchParams] = useSearchParams();
  const {
    user: { current: currentUser, employee },
    actions: { setCurrentUser },
  } = useContext(AppContext);

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (employee) employee.role = 'employee';
  }, [employee]);

  useEffect(() => {
    const token = searchParams.get('token');
    const _userId = searchParams.get('user_id') || searchParams.get('user-id');

    if (initialized && token && _userId) {
      HttpService.authorizationToken = `Token ${token}`;
      const userId = parseInt(_userId);

      initUser({ userId });
    }
  }, [initialized]);

  async function initUser({ userId }: { userId: number }) {
    try {
      const user = await UserService.getCurrentUser({ userId });

      setCurrentUser(user);
    } catch (err) {
      console.error(err);
    }
  }

  if (!currentUser) return <></>;

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} sx={{ overflowY: 'auto' }}>
      <Outlet />
    </Box>
  );
}

export default WebviewLayout;
