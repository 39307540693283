import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

function SideDialog({
  title,
  children,
  ...props
}: Omit<DialogProps, 'onClose' | 'title'> & {
  children: ReactNode;
  title: ReactNode;
  onClose: () => void;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: 0,
          height: '100%',
          margin: 0,
          maxHeight: '100%',
        },
      }}
      sx={{
        '.MuiDialog-container': { justifyContent: 'flex-end' },
        '.MuiDialogContent-root': { padding: 5 },
      }}
      {...props}
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box display="flex" justifyContent="flex-end">
          <Tooltip title={t('commons.actions.close')}>
            <IconButton onClick={() => props.onClose()} size="small">
              <Close color="action" />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography fontWeight={700} variant="h5">
          {title}
        </Typography>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default SideDialog;
