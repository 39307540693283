import { SvgIcon, SvgIconProps } from '@mui/material';

export function HelpIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 25" {...props}>
      <g clipPath="url(#clip0_2294_115936)">
        <path
          d="M12 2.5C10.0222 2.5 8.08879 3.08649 6.4443 4.1853C4.79981 5.28412 3.51809 6.8459 2.76121 8.67317C2.00433 10.5004 1.8063 12.5111 2.19215 14.4509C2.578 16.3907 3.53041 18.1725 4.92894 19.5711C6.32746 20.9696 8.10929 21.922 10.0491 22.3079C11.9889 22.6937 13.9996 22.4957 15.8268 21.7388C17.6541 20.9819 19.2159 19.7002 20.3147 18.0557C21.4135 16.4112 22 14.4778 22 12.5C22 11.1868 21.7413 9.88642 21.2388 8.67317C20.7363 7.45991 19.9997 6.35752 19.0711 5.42893C18.1425 4.50035 17.0401 3.76375 15.8268 3.2612C14.6136 2.75866 13.3132 2.5 12 2.5ZM12 20.5C10.4178 20.5 8.87104 20.0308 7.55544 19.1518C6.23985 18.2727 5.21447 17.0233 4.60897 15.5615C4.00347 14.0997 3.84504 12.4911 4.15372 10.9393C4.4624 9.38743 5.22433 7.96197 6.34315 6.84315C7.46197 5.72433 8.88743 4.9624 10.4393 4.65372C11.9911 4.34504 13.5997 4.50346 15.0615 5.10896C16.5233 5.71447 17.7727 6.73984 18.6518 8.05544C19.5308 9.37103 20 10.9177 20 12.5C20 14.6217 19.1572 16.6566 17.6569 18.1569C16.1566 19.6571 14.1217 20.5 12 20.5Z"
          fill="currentColor"
        />
        <path
          d="M11.0159 15.9398C11.0545 15.8874 11.0964 15.8373 11.1414 15.7898C11.2387 15.6961 11.3545 15.6217 11.482 15.5709C11.6096 15.5202 11.7464 15.494 11.8845 15.494C12.0227 15.494 12.1595 15.5202 12.287 15.5709C12.4146 15.6217 12.5303 15.6961 12.6276 15.7898C12.7246 15.8832 12.8014 15.9941 12.8535 16.1159C12.9055 16.2377 12.9319 16.3682 12.9311 16.4998C12.9361 16.6291 12.9148 16.7582 12.8683 16.8798C12.8213 17.0039 12.7463 17.1166 12.6487 17.2099C12.551 17.3032 12.4331 17.3749 12.3032 17.4198C12.1779 17.4727 12.0424 17.5 11.9055 17.5C11.7685 17.5 11.633 17.4727 11.5078 17.4198C11.3779 17.3749 11.2599 17.3032 11.1623 17.2099C11.0646 17.1166 10.9896 17.0039 10.9426 16.8798C10.884 16.7611 10.8553 16.631 10.8589 16.4998C10.8537 16.4332 10.8537 16.3664 10.8589 16.2998C10.8703 16.2371 10.8915 16.1765 10.9217 16.1198C10.9446 16.0561 10.9762 15.9955 11.0159 15.9398Z"
          fill="currentColor"
        />
        <path
          d="M10.3135 7.90143C10.7912 7.63812 11.333 7.49966 11.8845 7.5C12.6209 7.50546 13.3318 7.75801 13.893 8.2135C14.4542 8.66899 14.83 9.29845 14.9546 9.99183C15.0793 10.6852 14.945 11.3984 14.5751 12.0068C14.2052 12.6151 13.6233 13.0799 12.9311 13.3199V13.4999C12.9311 13.7651 12.8209 14.0194 12.6246 14.207C12.4283 14.3945 12.1621 14.4998 11.8845 14.4998C11.607 14.4998 11.3407 14.3945 11.1445 14.207C10.9482 14.0194 10.8379 13.7651 10.8379 13.4999V12.4999C10.8379 12.2347 10.9482 11.9803 11.1445 11.7928C11.3407 11.6053 11.607 11.4999 11.8845 11.4999C12.1621 11.4999 12.4283 11.3946 12.6246 11.207C12.8209 11.0195 12.9311 10.7651 12.9311 10.4999C12.9311 10.2347 12.8209 9.98037 12.6246 9.79284C12.4283 9.60531 12.1621 9.49995 11.8845 9.49995C11.7001 9.49925 11.5188 9.54513 11.3589 9.63293C11.199 9.72073 11.0662 9.84734 10.974 9.99994C10.9086 10.1194 10.8185 10.2251 10.7092 10.3105C10.6 10.3958 10.4738 10.4592 10.3382 10.4968C10.2026 10.5344 10.0605 10.5454 9.9204 10.5291C9.78028 10.5128 9.64504 10.4697 9.52284 10.4022C9.40063 10.3347 9.29398 10.2443 9.20929 10.1364C9.1246 10.0285 9.06363 9.90533 9.03005 9.77433C8.99647 9.64334 8.99097 9.50721 9.01388 9.37413C9.03679 9.24106 9.08763 9.11378 9.16336 8.99997C9.43911 8.54364 9.83579 8.16475 10.3135 7.90143Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2294_115936">
          <rect fill="currentColor" height="24" transform="translate(0 0.5)" width="24" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
