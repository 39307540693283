import { Check, Close } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

function FreeFeaturesTable(): JSX.Element {
  return (
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={3}>
      <Box
        bgcolor="#ECFBF4"
        borderRadius={2}
        display="flex"
        flexDirection="column"
        gap={2}
        padding={4}
        width={{ md: 'calc((100% - 24px) / 2)' }}
      >
        <Box alignItems="center" display="flex" gap={2}>
          <Check sx={{ color: '#2AC682' }} />
          <Typography fontWeight={700} variant="body2">
            Vos fonctionnalités dans l'offre Gratuite :
          </Typography>
        </Box>
        <Typography component="ul" variant="body2">
          <li>Gestion des salariés</li>
          <li>Challenges mensuels automatiques</li>
          <li>Création d'actualités</li>
          <li>Statistiques globales</li>
          <li>Enquête mobilité de base</li>
        </Typography>
      </Box>
      <Box
        bgcolor="#FFF3F3"
        borderRadius={2}
        display="flex"
        flexDirection="column"
        gap={2}
        padding={4}
        width={{ md: 'calc((100% - 24px) / 2)' }}
      >
        <Box alignItems="center" display="flex" gap={2}>
          <Close sx={{ color: '#D34949' }} />
          <Typography fontWeight={700} variant="body2">
            Vous ne bénéficierez plus de :
          </Typography>
        </Box>
        <Box>
          <Typography fontWeight={700} variant="body2">
            Forfait Mobilités Durables :
          </Typography>
          <Typography component="ul" variant="body2">
            <li>Configuration de l'indemnité</li>
            <li>Liste des bénéficiaires</li>
            <li>Récupération des indemnité à verser et preuves de pédalage</li>
          </Typography>
        </Box>
        <Box>
          <Typography fontWeight={700} variant="body2">
            Animation :
          </Typography>
          <Typography component="ul" variant="body2">
            <li>Création de challenges personnalisés</li>
            <li>Tops du moment (maillots)</li>
            <li>Création d'actualités</li>
          </Typography>
        </Box>
        <Box>
          <Typography fontWeight={700} variant="body2">
            Administration :
          </Typography>
          <Typography component="ul" variant="body2">
            <li>Gestion des équipes</li>
            <li>Ajout d'administrateurs multiples</li>
          </Typography>
        </Box>
        <Box>
          <Typography fontWeight={700} variant="body2">
            Économies CO2e :
          </Typography>
          <Typography component="ul" variant="body2">
            <li>Statistiques avancées</li>
            <li>Enquête Mobilité avancée</li>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default FreeFeaturesTable;
