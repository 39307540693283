import { Check } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components';

const sizeRangesKeys = [
  'companyBetween1and50',
  'companyBetween51and100',
  'companyBetween101and500',
  'companyMoreThan500',
] as const;

export type TSizeRange = (typeof sizeRangesKeys)[number];

export const sizeRanges: Array<{ key: TSizeRange; min: number; max?: number }> = [
  { key: 'companyBetween1and50', min: 0, max: 50 },
  { key: 'companyBetween51and100', min: 51, max: 100 },
  { key: 'companyBetween101and500', min: 101, max: 500 },
  { key: 'companyMoreThan500', min: 501 },
];

function SizeRangeForm({
  disabled,
  sizeRangeIndex,
  setSizeRangeIndex,
}: {
  disabled: boolean;
  setSizeRangeIndex: (index: number) => void;
  sizeRangeIndex: number;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box columnGap={1} display="flex" flexWrap="wrap" rowGap={1}>
      {sizeRanges.map(({ key, min, max }, index) => {
        const active = index === sizeRangeIndex;

        return (
          <Button
            color="inherit"
            disabled={disabled}
            endIcon={active && <Check sx={{ color: '#2AC682' }} />}
            key={key}
            onClick={() => setSizeRangeIndex(index)}
            sx={{
              bgcolor: active ? '#ECFBF4' : undefined,
              borderColor: active ? '#2AC682' : '#DDE0E7',
              color: '#212121',
            }}
            variant="outlined"
          >
            {t('companies.size_dialog.count', { context: max ? '' : 'no_max', min, max })}
          </Button>
        );
      })}
    </Box>
  );
}

export default SizeRangeForm;
