import { SvgIcon, SvgIconProps } from '@mui/material';

export function PremiumIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 10 10" {...props}>
      <path
        d="M9.16683 3.54199L7.50016 1.04199H2.50016L0.833496 3.54199M9.16683 3.54199L5.00016 8.95866M9.16683 3.54199H0.833496M5.00016 8.95866L0.833496 3.54199M5.00016 8.95866L3.3335 3.54199L4.5835 1.04199M5.00016 8.95866L6.66683 3.54199L5.41683 1.04199"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.625"
      />
    </SvgIcon>
  );
}
