import { TheaterComedy } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useContext } from 'react';

import { AppContext } from '../app/context';

import { Button } from './button';

function ImpersonatingBanner(): JSX.Element {
  const {
    user: { current: currentUser },
  } = useContext(AppContext);

  return (
    <Box
      alignItems="center"
      bgcolor="#FAE3EE"
      display="flex"
      gap={3}
      minHeight={60}
      paddingX={3}
      paddingY={1}
      width="100%"
    >
      <Box alignItems="center" display="flex" flexGrow={1} gap={2}>
        <TheaterComedy sx={{ color: '#DD428D' }} />
        <Typography variant="body2">
          Vous êtes connecté·e en tant que "{currentUser?.username}" ({currentUser?.email})
        </Typography>
      </Box>
      <Box
        alignItems={{ xs: 'stretch', md: 'center' }}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={1}
      >
        <Button
          onClick={() => {
            try {
              localStorage.removeItem('impersonate_authorization_token');
              localStorage.removeItem('impersonate_user_id');

              window.open('/', '_self');
            } catch {
              console.error('localStorage access is denied');
            }
          }}
          size="medium"
          sx={{ color: '#DD428D', textDecoration: 'underline !important' }}
          variant="text"
        >
          Revenir à son propre compte
        </Button>
      </Box>
    </Box>
  );
}

export default ImpersonatingBanner;
