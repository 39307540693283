import { SvgIcon, SvgIconProps } from '@mui/material';

export function StatsIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M2.25 2.625V20.25H22.875C23.1734 20.25 23.4595 20.3685 23.6705 20.5795C23.8815 20.7905 24 21.0766 24 21.375C24 21.6734 23.8815 21.9595 23.6705 22.1705C23.4595 22.3815 23.1734 22.5 22.875 22.5H1.125C0.826631 22.5 0.540483 22.3815 0.329505 22.1705C0.118526 21.9595 0 21.6734 0 21.375L0 2.625C0 2.32663 0.118526 2.04048 0.329505 1.8295C0.540483 1.61853 0.826631 1.5 1.125 1.5C1.42337 1.5 1.70952 1.61853 1.9205 1.8295C2.13147 2.04048 2.25 2.32663 2.25 2.625ZM23.67 6.42L15.795 14.295C15.5841 14.5057 15.2981 14.624 15 14.624C14.7019 14.624 14.4159 14.5057 14.205 14.295L10.5 10.59L6.42 14.67C6.20678 14.869 5.92463 14.9774 5.63304 14.9723C5.34146 14.9673 5.06322 14.8492 4.857 14.643C4.65079 14.4368 4.53272 14.1585 4.52768 13.867C4.52265 13.5754 4.63104 13.2932 4.83 13.08L9.705 8.205C9.91594 7.99432 10.2019 7.87599 10.5 7.87599C10.7981 7.87599 11.0841 7.99432 11.295 8.205L15 11.91L22.08 4.83C22.2932 4.63104 22.5754 4.52265 22.867 4.52768C23.1585 4.53272 23.4368 4.65079 23.643 4.857C23.8492 5.06322 23.9673 5.34146 23.9723 5.63304C23.9774 5.92463 23.869 6.20678 23.67 6.42Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
