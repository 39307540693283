import { SvgIcon, SvgIconProps } from '@mui/material';

export function LeaderboardIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        clipRule="evenodd"
        d="M8 6.5C8 5.11929 9.11929 4 10.5 4H13.5C14.8807 4 16 5.11929 16 6.5V13.05C16.1616 13.0172 16.3288 13 16.5 13H19.5C20.8807 13 22 14.1193 22 15.5V19H23C23.5523 19 24 19.4477 24 20C24 20.5523 23.5523 21 23 21H21H15H9H3H1C0.447715 21 0 20.5523 0 20C0 19.4477 0.447715 19 1 19H2V12.5C2 11.1193 3.11929 10 4.5 10H7.5C7.67123 10 7.83845 10.0172 8 10.05V6.5ZM20 15.5V19H16V15.5C16 15.2239 16.2239 15 16.5 15H19.5C19.7761 15 20 15.2239 20 15.5ZM14 15.5V19H10V12.5V6.5C10 6.22386 10.2239 6 10.5 6H13.5C13.7761 6 14 6.22386 14 6.5V15.5ZM8 12.5V19H4V12.5C4 12.2239 4.22386 12 4.5 12H7.5C7.77614 12 8 12.2239 8 12.5ZM10.7293 9.03789C10.7056 8.96669 10.6961 8.89152 10.7014 8.81666C10.7067 8.74181 10.7267 8.66873 10.7603 8.6016C10.7938 8.53448 10.8403 8.47462 10.897 8.42545C10.9537 8.37629 11.0195 8.33877 11.0907 8.31504L11.9478 8.02933C12.0337 8.00069 12.1252 7.99288 12.2147 8.00654C12.3042 8.02019 12.3892 8.05492 12.4627 8.10787C12.5361 8.16082 12.5959 8.23046 12.6372 8.31106C12.6784 8.39166 12.7 8.48092 12.7 8.57146V11.4286C12.7 11.5801 12.6397 11.7255 12.5326 11.8326C12.4254 11.9398 12.2801 12 12.1285 12C11.977 12 11.8316 11.9398 11.7245 11.8326C11.6173 11.7255 11.5571 11.5801 11.5571 11.4286V9.36431L11.4521 9.39931C11.3809 9.42305 11.3058 9.43253 11.2309 9.42721C11.156 9.4219 11.083 9.40188 11.0158 9.36832C10.9487 9.33476 10.8889 9.28831 10.8397 9.23161C10.7905 9.17491 10.753 9.10909 10.7293 9.03789Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
