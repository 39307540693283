import { useAmplitudeTracker } from '@geovelo-frontends/commons';
import { ThemeProvider } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppContext } from '../app/context';
import { premiumTheme } from '../app/theme';

import { Button } from './button';

function UpgradeOfferButton({
  size,
  offer,
  context,
}: {
  context: string;
  offer?: 'premium';
  size?: 'small' | 'medium';
}): JSX.Element {
  const {
    partner: { current: currentPartner, contract },
    user: { employee },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { trackEvent } = useAmplitudeTracker();

  return (
    <ThemeProvider theme={premiumTheme}>
      <Button
        component={Link}
        onClick={() => {
          trackEvent('Upgrade Offer Button Clicked', {
            context,
            employee_id: employee?.id,
            partner_id: currentPartner?.id,
            partner_code: currentPartner?.code,
            user_type: 'administrator',
            contract_type: contract?.contractTemplate.code,
          });
        }}
        size={size || 'large'}
        to="/admin/offers"
        variant="contained"
      >
        {offer === 'premium'
          ? t('companies.actions.upgrade_offer_premium')
          : t('companies.actions.upgrade_offer')}
      </Button>
    </ThemeProvider>
  );
}

export default UpgradeOfferButton;
