import { useState } from 'react';

function useImpersonating() {
  const [impersonating] = useState(() => {
    try {
      const item = localStorage.getItem('impersonate_authorization_token');

      return Boolean(item);
    } catch {
      console.error('localStorage access is denied');
    }

    return false;
  });

  return { impersonating };
}

export default useImpersonating;
