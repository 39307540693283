import { SvgIcon, SvgIconProps } from '@mui/material';

export function ChevronRightIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <path
        d="M6 14.25L11.25 9L6 3.75"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
