import { CompanyIcon } from '@geovelo-frontends/commons';
import { Menu } from '@mui/icons-material';
import { AppBar, Avatar, Box, IconButton, Skeleton, Toolbar, Typography } from '@mui/material';
import { useContext } from 'react';

import { AppContext } from '../../app/context';

function Header({ handleDrawerToggle }: { handleDrawerToggle: () => void }): JSX.Element {
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);

  return (
    <AppBar
      elevation={0}
      position="fixed"
      sx={({ zIndex }) => ({
        backgroundColor: '#fff',
        color: 'inherit',
        display: { xs: 'block', sm: 'none' },
        zIndex: zIndex.drawer + 1,
      })}
    >
      <Toolbar>
        <Box alignItems="center" display="flex" gap={1}>
          {currentPartner?.icon ? (
            <Avatar src={currentPartner.icon} />
          ) : (
            <Avatar sx={({ palette }) => ({ bgcolor: palette.primary.main })}>
              <CompanyIcon />
            </Avatar>
          )}
          <Typography noWrap fontWeight={700} title={currentPartner?.title}>
            {currentPartner?.title || <Skeleton variant="text" width={100} />}
          </Typography>
        </Box>
        <Box flexGrow={1} />
        <IconButton
          aria-label="open drawer"
          color="default"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <Menu />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
