import { G, Path, Svg } from '@react-pdf/renderer';

function HeaderBackground(): JSX.Element {
  return (
    <Svg viewBox="0 0 420 129" width="100%">
      <G opacity="0.8">
        <Path
          d="M164.673 32.5775C173.406 22.7686 216.561 7.93608 237.047 1.74595L-6.29382 1.74597L-6.29381 99.4785C4.58199 97.3358 30.32 87.9078 46.2659 67.3375C66.1982 41.6247 103.215 51.267 122.436 50.5527C141.656 49.8385 153.758 44.8388 164.673 32.5775Z"
          fill="#A7C4F4"
        />
        <Path
          d="M385.305 60.8805C396.207 79.0463 417.841 91.8376 429.547 96.123L429.547 -3.96931L-25.6936 -0.755171C-18.654 0.316194 2.47274 5.53019 30.6628 17.8152C65.9003 33.1715 103.036 33.2905 131.155 30.0764C159.274 26.8623 216.342 30.0764 245.766 41.6234C275.19 53.1703 302.834 46.1469 337.36 41.6233C371.885 37.0998 377.607 48.0537 385.305 60.8805Z"
          fill="#A3F1CF"
          opacity="0.8"
        />
      </G>
    </Svg>
  );
}

export default HeaderBackground;
