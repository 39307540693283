import { Helmet } from 'react-helmet-async';

export function LinkedInTracker(): JSX.Element {
  if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development') {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`_linkedin_partner_id = "6049300"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
        </script>
        <script type="text/javascript">
          {`(function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);`}
        </script>
        <script type="text/javascript">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-M3WFPHZP');`}
        </script>
      </Helmet>
      <img
        alt=""
        height="1"
        src="https://px.ads.linkedin.com/collect/?pid=6049300&fmt=gif"
        style={{ display: 'none' }}
        width="1"
      />
      <noscript>
        <iframe
          height="0"
          src="https://www.googletagmanager.com/ns.html?id=GTM-M3WFPHZP"
          style={{ display: 'none', visibility: 'hidden' }}
          width="0"
        />
      </noscript>
    </>
  );
}
