import { SvgIcon, SvgIconProps } from '@mui/material';

export function CopyIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <g clipPath="url(#clip0_7541_232503)">
        <path
          d="M16.6667 7.5H9.16667C8.24619 7.5 7.5 8.24619 7.5 9.16667V16.6667C7.5 17.5871 8.24619 18.3333 9.16667 18.3333H16.6667C17.5871 18.3333 18.3333 17.5871 18.3333 16.6667V9.16667C18.3333 8.24619 17.5871 7.5 16.6667 7.5Z"
          fill="white"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M4.16602 12.5013H3.33268C2.89065 12.5013 2.46673 12.3257 2.15417 12.0131C1.84161 11.7006 1.66602 11.2767 1.66602 10.8346V3.33464C1.66602 2.89261 1.84161 2.46868 2.15417 2.15612C2.46673 1.84356 2.89065 1.66797 3.33268 1.66797H10.8327C11.2747 1.66797 11.6986 1.84356 12.0112 2.15612C12.3238 2.46868 12.4993 2.89261 12.4993 3.33464V4.16797"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_7541_232503">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
