import { EmployeeService } from '@geovelo-frontends/commons';
import {
  Box,
  CircularProgress,
  DialogProps,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';

import { AppContext } from '../../app/context';
import { Button, Dialog } from '../../components';
import { TJob, jobLabels, jobs } from '../../pages/add-company/personal-data';

function JobDialog({
  ...props
}: Omit<DialogProps, 'onClose'> & { onClose: () => void }): JSX.Element {
  const [job, setJob] = useState<TJob | ''>('');
  const [otherJob, setOtherJob] = useState<string>('');
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    partner: { current: currentPartner },
    user: { employee },
    actions: { setUserEmployee },
  } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  async function handleSubmit() {
    if (!currentPartner || !employee) return;

    setSubmitting(true);

    try {
      const updatedEmployee = await EmployeeService.updateEmployee(currentPartner, employee.id, {
        surveyAnswers: {
          ...employee.surveyAnswers,
          job,
          otherJob: job === 'other' ? otherJob : '',
        },
      });

      setUserEmployee(updatedEmployee);

      props.onClose();
    } catch (err) {
      enqueueSnackbar(
        "Une erreur est survenue, la fonction au sein de l'entreprise n'a pas pu être modifiée",
        { variant: 'error' },
      );
    }

    setSubmitting(false);
  }

  return (
    <Dialog disableBackgroundClick disableCloseButton fullWidth maxWidth="sm" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontSize="1.25rem" fontWeight={600}>
          Sélectionnez votre fonction au sein de l'entreprise
        </Typography>
        <Box display="flex" flexDirection="column" gap={1}>
          <FormControl margin="none" size="small">
            <FormLabel component="legend" id="site-label">
              <Typography variant="caption">Fonction au sein de l'entreprise</Typography>
            </FormLabel>
            <Select
              id="job"
              labelId="job-label"
              name="job"
              onChange={({ target: { value } }) => setJob(value as TJob)}
              renderValue={(key) => jobLabels[key]}
              size="small"
              value={job}
            >
              <MenuItem disabled value="">
                /
              </MenuItem>
              {jobs.map((key) => (
                <MenuItem key={key} value={key}>
                  {jobLabels[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {job === 'other' && (
            <TextField
              required
              id="otherJob"
              InputLabelProps={{ shrink: true }}
              name="otherJob"
              onChange={({ target: { value } }) => setOtherJob(value)}
              size="small"
              value={otherJob}
              variant="outlined"
            />
          )}
        </Box>
        <Box alignItems="center" display="flex" gap={3} justifyContent="flex-end">
          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={handleSubmit}
            startIcon={isSubmitting && <CircularProgress color="inherit" size={16} thickness={4} />}
            variant="contained"
          >
            OK
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default JobDialog;
