import { SvgIcon, SvgIconProps } from '@mui/material';

export function FMDIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 23 22" {...props}>
      <path
        d="M16.7757 10.4563V5.01232C16.7757 2.67792 13.7541 0.916992 9.74915 0.916992C5.74423 0.916992 2.72266 2.67792 2.72266 5.01232V13.833C2.72266 16.1674 5.74423 17.9283 9.74915 17.9283C10.1991 17.9283 10.6489 17.9037 11.0965 17.8557C11.822 19.5847 13.3758 20.7931 15.1874 21.0381C16.9977 21.283 18.8009 20.5287 19.9346 19.052C21.0683 17.5741 21.3666 15.5918 20.7186 13.8308C20.0718 12.0686 18.5742 10.7876 16.7758 10.4565L16.7757 10.4563ZM9.74915 2.80714C12.9199 2.80714 14.9427 4.11399 14.9427 5.01232C14.9427 5.91064 12.9199 7.21749 9.74915 7.21749C6.5784 7.21749 4.55566 5.91064 4.55566 5.01232C4.55566 4.11399 6.5784 2.80714 9.74915 2.80714ZM4.55566 7.82549C5.82061 8.62288 7.65006 9.10774 9.74915 9.10774C11.8482 9.10774 13.6776 8.62288 14.9427 7.82549V9.42276C14.9427 10.3211 12.9199 11.6279 9.74915 11.6279C6.5784 11.6279 4.55566 10.3211 4.55566 9.42276V7.82549ZM9.74915 16.0382C6.57837 16.0382 4.55566 14.7313 4.55566 13.833V12.2357C5.82061 13.0331 7.65006 13.518 9.74915 13.518C10.2241 13.518 10.6979 13.4922 11.1692 13.4392C10.8387 14.153 10.6668 14.9332 10.6657 15.7232V15.9976C10.3816 16.0382 10.0726 16.0382 9.74915 16.0382ZM15.8592 19.1884C14.4999 19.1884 13.2743 18.3443 12.7541 17.0497C12.2338 15.7539 12.5213 14.2637 13.4832 13.2732C14.4438 12.2814 15.889 11.9848 17.1455 12.5213C18.4009 13.0578 19.2196 14.3216 19.2196 15.7232C19.2196 17.6368 17.7148 19.1884 15.8592 19.1884Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
