import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

export function CTACard({
  bgColor,
  icon,
  title,
  description,
  actions,
  size,
}: {
  actions: ReactNode;
  bgColor?: string;
  description?: ReactNode;
  icon?: string;
  size?: 'small';
  title: ReactNode;
}): JSX.Element {
  return (
    <Box
      alignItems={{ xs: 'flex-start', md: 'center' }}
      bgcolor={bgColor || '#fff'}
      borderRadius={4}
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      gap={3}
      padding={3}
      width="100%"
    >
      {icon && (
        <Box alignItems="center" display="flex" flexShrink={0}>
          <img height="60px" src={icon} width="60px" />
        </Box>
      )}
      <Box display="flex" flexDirection="column" flexGrow={1} gap={1}>
        <Typography fontSize={size === 'small' ? '1rem' : '1.15rem'} fontWeight={600}>
          {title}
        </Typography>
        {description && <Typography variant="body2">{description}</Typography>}
      </Box>
      <Box
        alignItems="center"
        alignSelf={{ xs: 'flex-end', md: 'center' }}
        display="flex"
        flexShrink={0}
        flexWrap="wrap"
        gap={2}
        justifyContent="flex-end"
      >
        {actions}
      </Box>
    </Box>
  );
}
