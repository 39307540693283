import { Box, DialogProps, Typography } from '@mui/material';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../../app/context';
import { Button, Dialog } from '../../components';
import useOfferDialog from '../../hooks/offer-dialog';

function StandardContractDialog({
  surveyAnswerKey,
  ...props
}: Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
  surveyAnswerKey: string | null;
}): JSX.Element {
  const {
    partner: { current: currentPartner, contract },
  } = useContext(AppContext);
  useOfferDialog(props.open, surveyAnswerKey);

  if (!currentPartner || !contract?.endDateTime) return <></>;

  return (
    <Dialog disableBackgroundClick fullWidth maxWidth="sm" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontSize="1.25rem" fontWeight={600}>
          Félicitations, vous êtes passé à l'offre{' '}
          <span style={{ color: '#743AEF' }}>Standard</span> 🎉
        </Typography>
        <Typography variant="body2">
          Créez des sites et des équipes, des challenges personnalisés, ajoutez plusieurs
          administrateurs, visualisez vos statistiques avancées, lancez et visualisez les résultats
          de l'Enquête Mobilité.
        </Typography>
        <Box alignItems="center" display="flex" gap={3} justifyContent="flex-end">
          <Button
            color="primary"
            component={Link}
            onClick={() => props.onClose()}
            to="/admin/offers"
            variant="outlined"
          >
            Voir la liste des fonctionnalités
          </Button>
          <Button color="primary" onClick={() => props.onClose()} variant="contained">
            OK
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default StandardContractDialog;
