import { SvgIcon, SvgIconProps } from '@mui/material';

export function MagicWandIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 20 21" {...props}>
      <path
        d="M16.875 10.0435V13.7935"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <path
        d="M15 11.9185H18.75"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <path
        d="M6.5625 3.16846V6.29346"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <path
        d="M5 4.73096H8.125"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <path
        d="M13.125 14.4185V16.9185"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <path
        d="M11.875 15.6685H14.375"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <path
        d="M14.5646 2.98348L2.94707 14.601C2.70299 14.8451 2.70299 15.2408 2.94707 15.4849L4.56292 17.1008C4.807 17.3448 5.20272 17.3448 5.4468 17.1008L17.0643 5.48322C17.3084 5.23914 17.3084 4.84341 17.0643 4.59933L15.4485 2.98348C15.2044 2.73941 14.8087 2.73941 14.5646 2.98348Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <path
        d="M11.25 6.29346L13.75 8.79346"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </SvgIcon>
  );
}
