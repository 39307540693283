import { SvgIcon, SvgIconProps } from '@mui/material';

export function RefreshIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path d="M2.86865 15.1868V11.2969H6.75854" fill="#326AC2" />
      <path
        d="M2.86865 15.1868V11.2969H6.75854"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M17.1316 4.81348V8.70336H13.2417"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M4.49592 8.05534C4.82473 7.12617 5.38355 6.29543 6.12025 5.64064C6.85695 4.98586 7.74752 4.52836 8.70885 4.31085C9.67019 4.09333 10.671 4.12289 11.6178 4.39676C12.5646 4.67063 13.4266 5.17988 14.1234 5.87701L17.1316 8.70366M2.86865 11.2969L5.87683 14.1236C6.5736 14.8207 7.43562 15.3299 8.38244 15.6038C9.32926 15.8777 10.33 15.9072 11.2914 15.6897C12.2527 15.4722 13.1433 15.0147 13.88 14.3599C14.6167 13.7051 15.1755 12.8744 15.5043 11.9452"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
