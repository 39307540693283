import { useAmplitudeTracker } from '@geovelo-frontends/commons';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppContext } from '../app/context';

import { Button } from './button';

function SeeOffersButton({
  variant,
  context,
  onClick,
}: {
  context: string;
  onClick: () => void;
  variant?: 'outlined';
}): JSX.Element {
  const {
    partner: { current: currentPartner, contract },
    user: { employee },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { trackEvent } = useAmplitudeTracker();

  return (
    <Button
      color="primary"
      component={Link}
      onClick={() => {
        trackEvent('See Offers Button Clicked', {
          context,
          employee_id: employee?.id,
          partner_id: currentPartner?.id,
          partner_code: currentPartner?.code,
          user_type: 'administrator',
          contract_type: contract?.contractTemplate.code,
        });
        onClick();
      }}
      to="/admin/offers"
      variant={variant || 'contained'}
    >
      {t('companies.actions.see_offers')}
    </Button>
  );
}

export default SeeOffersButton;
