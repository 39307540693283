import { Check } from '@mui/icons-material';
import { Box, DialogProps, Typography } from '@mui/material';
import { useContext } from 'react';

import { AppContext } from '../../app/context';
import { Button, Dialog } from '../../components';
import useOfferDialog from '../../hooks/offer-dialog';

const features = [
  'Gestion du Forfait Mobilités Durables',
  'Création de challenges personnalisés et automatiques',
  'Statistiques détaillées',
  'Gestion par équipes',
  "Accès aux résultats globaux de l'enquête mobilité",
];

function PremiumContractDialog({
  surveyAnswerKey,
  ...props
}: Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
  surveyAnswerKey: string | null;
}): JSX.Element {
  const {
    partner: { current: currentPartner, contract },
  } = useContext(AppContext);
  useOfferDialog(props.open, surveyAnswerKey);

  if (!currentPartner || !contract?.endDateTime) return <></>;

  return (
    <Dialog disableBackgroundClick fullWidth maxWidth="sm" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontSize="1.25rem" fontWeight={600}>
          Félicitations, vous êtes passé à l'offre <span style={{ color: '#743AEF' }}>Premium</span>{' '}
          🎉
        </Typography>
        <Typography variant="body2">
          Votre passage à l'offre Premium est confirmé. Accédez désormais à des nouvelles
          fonctionnalités pour renforcer la pratique du vélo dans votre entreprise, notamment :
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          {features.map((feature, index) => (
            <Box alignItems="center" display="flex" gap={1} key={index}>
              <Check sx={{ color: '#2AC682' }} />
              <Typography variant="body2">{feature}</Typography>
            </Box>
          ))}
        </Box>
        <Box alignItems="center" display="flex" gap={3} justifyContent="flex-end">
          <Button color="primary" onClick={() => props.onClose()} variant="contained">
            OK
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default PremiumContractDialog;
