import { SvgIcon, SvgIconProps } from '@mui/material';

export function EditIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M22 7.24C22.0008 7.10839 21.9756 6.97793 21.9258 6.85609C21.876 6.73426 21.8027 6.62344 21.71 6.53L17.47 2.29C17.3766 2.19732 17.2658 2.12399 17.1439 2.07423C17.0221 2.02447 16.8916 1.99924 16.76 2C16.6284 1.99924 16.4979 2.02447 16.3761 2.07423C16.2543 2.12399 16.1435 2.19732 16.05 2.29L13.22 5.12L2.29002 16.05C2.19734 16.1434 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8946 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8C21.8013 7.90308 21.8757 7.79153 21.93 7.67C21.9397 7.59029 21.9397 7.50971 21.93 7.43C21.9347 7.38345 21.9347 7.33655 21.93 7.29L22 7.24ZM6.83002 20H4.00002V17.17L13.93 7.24L16.76 10.07L6.83002 20ZM18.17 8.66L15.34 5.83L16.76 4.42L19.58 7.24L18.17 8.66Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
