import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import FooterBackground from './footer-background copy';
import GeoveloIcon from './geovelo-icon';
import HeaderBackground from './header-background';

const styles = StyleSheet.create({
  page: {
    alignItems: 'stretch',
    color: '#212121',
    flexDirection: 'column',
    fontSize: 15,
    fontFamily: 'Nunito',
    gap: 30,
    lineHeight: 1.35,
    paddingHorizontal: 50,
    paddingTop: 30,
    paddingBottom: 20,
    position: 'relative',
  },
  headerBackground: {
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 50,
  },
  avatar: {
    alignItems: 'center',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: 50,
    display: 'flex',
    height: 100,
    justifyContent: 'center',
    width: 100,
  },
  footerBackground: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
  },
});

export function CommunityFlyerDocument({
  qrCode,
  currentPartner,
}: {
  currentPartner: { icon: string | null; title: string };
  qrCode: string;
}): JSX.Element {
  return (
    <Document
      author="Geovelo Entreprise"
      creator="Geovelo Entreprise"
      language="fr"
      title={`${currentPartner.title} lance le FMD avec Geovelo Entreprise`}
    >
      <Page wrap size="A4" style={styles.page}>
        <View fixed style={styles.headerBackground}>
          <HeaderBackground />
        </View>
        <View style={styles.content}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              gap: -10,
              justifyContent: 'flex-end',
            }}
          >
            <View style={styles.avatar}>
              <GeoveloIcon />
            </View>
            {currentPartner.icon && (
              <View style={styles.avatar}>
                <Image
                  src={currentPartner.icon}
                  style={{
                    height: 60,
                    objectFit: 'contain',
                    objectPosition: 'center center',
                    width: 60,
                  }}
                />
              </View>
            )}
          </View>
          <View>
            <Text style={{ fontSize: 25, fontWeight: 700 }}>{currentPartner.title}</Text>
            <Text style={{ fontSize: 25 }}>avec Geovelo Entreprise</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Text style={{ fontSize: 18, fontWeight: 700 }}>Quel est ce nouvel avantage ? 💡</Text>
            <Text>
              {currentPartner.title} a rejoint Geovelo Entreprise pour réduire son impact carbone
              grâce au vélo. Téléchargez Geovelo et rejoignez vos collègues&nbsp;!
            </Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Text style={{ fontSize: 18, fontWeight: 700 }}>
              Pourquoi rejoindre vos collègues ? 🚲
            </Text>
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <View style={{ display: 'flex', flexDirection: 'row', gap: 10, marginLeft: 10 }}>
                <View>
                  <Text>•</Text>
                </View>
                <View>
                  <Text>Participer aux challenges vélo,</Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', gap: 10, marginLeft: 10 }}>
                <View>
                  <Text>•</Text>
                </View>
                <View>
                  <Text>Mesurer l'impact carbone des déplacements,</Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', gap: 10, marginLeft: 10 }}>
                <View>
                  <Text>•</Text>
                </View>
                <View>
                  <Text>Suivre les économies réalisées,</Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', gap: 10, marginLeft: 10 }}>
                <View>
                  <Text>•</Text>
                </View>
                <View>
                  <Text>
                    Comptabiliser vos trajets - et le cas échéant, obtenir des{'\n'}justificatifs
                    pour obtenir le Forfait Mobilités Durables,
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', gap: 10, marginLeft: 10 }}>
                <View>
                  <Text>•</Text>
                </View>
                <View>
                  <Text>
                    Participer à des défis pour vous aider à (re)découvrir les{'\n'}bienfaits du
                    vélo.
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ flexGrow: 1 }} />
          <View
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: 50,
            }}
          >
            <View style={{ alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }}>
              <Text style={{ fontWeight: 700 }}>Rejoignez {currentPartner.title}</Text>
              <Text style={{ fontWeight: 700 }}>sur Geovelo Entreprise !</Text>
            </View>
            <View style={{ height: 80, width: 80 }}>
              <Image
                source={{ uri: qrCode, method: 'GET', headers: {}, body: '' }}
                style={{
                  height: 80,
                  objectFit: 'contain',
                  objectPosition: 'center center',
                  width: 80,
                }}
              />
            </View>
          </View>
        </View>
        <View fixed style={styles.footerBackground}>
          <FooterBackground />
        </View>
      </Page>
    </Document>
  );
}
