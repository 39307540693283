import { Crisp } from 'crisp-sdk-web';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../app/context';
import { environment } from '../environment';

let configured = false;

function useCrisp() {
  const [initialized, setInitialized] = useState(Boolean(window.$crisp?.config));
  const {
    partner: { current: currentPartner },
    user: { current: currentUser, employee },
  } = useContext(AppContext);

  useEffect(() => {
    if (!configured && environment.crisp?.websiteId) {
      try {
        Crisp.configure(environment.crisp.websiteId);
        Crisp.chat.hide();
        configured = true;

        Crisp.chat.onChatClosed(() => Crisp.chat.hide());
      } catch (err) {
        console.error(err);
      }
    }
  }, []);

  useEffect(() => {
    if (Boolean(window.$crisp?.config)) setInitialized(true);
  }, [window.$crisp]);

  useEffect(() => {
    if (initialized && currentUser) {
      try {
        Crisp.user.setEmail(currentUser.email);
        Crisp.user.setNickname(employee?.name || currentUser.username || '');
        Crisp.session.setData({
          userId: currentUser.id,
          employeeId: employee?.id,
          userType: employee?.role || 'user',
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, [initialized, currentUser, employee]);

  useEffect(() => {
    if (initialized && currentPartner) {
      try {
        Crisp.user.setCompany(currentPartner.title, {});
        Crisp.session.setData({
          partnerId: currentPartner.id,
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, [initialized, currentPartner]);

  function showChat() {
    try {
      if (!Crisp.chat.isVisible()) Crisp.chat.show();
      if (!Crisp.chat.isChatOpened()) Crisp.chat.open();
    } catch (err) {
      console.error(err);
    }
  }

  return { initialized, showChat };
}

export default useCrisp;
