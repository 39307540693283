import { UserPlace, UserService } from '@geovelo-frontends/commons';
import { Close } from '@mui/icons-material';
import { Box, CircularProgress, DialogProps, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../app/context';

import { Button } from './button';
import { Dialog } from './dialog';
import { RemoveIcon } from './icons';

function RemovePlaceDialog({
  place,
  ...props
}: Omit<DialogProps, 'onClose'> & {
  place: UserPlace;
  onClose: (removed?: boolean) => void;
}): JSX.Element {
  const [loading, setLoading] = useState(false);
  const {
    user: { works },
    actions: { setUserHome, setUserWorks },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  async function handleRemove() {
    if (!place.id || typeof place.id !== 'number') return;

    setLoading(true);

    try {
      await UserService.removePlace(place.id);

      if (place.type === 'home') setUserHome(null);
      else if (place.type === 'work' && works)
        setUserWorks(works.slice().filter(({ id }) => id !== place.id));

      enqueueSnackbar(t("L'adresse a bien été supprimée"));
      props.onClose(true);
    } catch {
      enqueueSnackbar(t("L'adresse n'a pas pu être supprimée"), {
        variant: 'error',
      });
    }

    setLoading(false);
  }

  return (
    <Dialog fullWidth loading={loading} maxWidth="sm" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontSize="1.25rem" fontWeight={600}>
          Supprimer cette adresse ?
        </Typography>
        <Typography component="div" variant="body2">
          Vous vous apprêtez à supprimer cette adresse. Attention, cette action est définitive et
          irréversible.
        </Typography>
        <Box alignItems="center" display="flex" gap={3} justifyContent="flex-end">
          <Button
            color="primary"
            disabled={loading}
            onClick={() => props.onClose()}
            startIcon={<Close />}
            variant="outlined"
          >
            {t('commons.actions.cancel')}
          </Button>
          <Button
            color="error"
            disabled={loading}
            onClick={handleRemove}
            startIcon={
              loading ? (
                <CircularProgress color="inherit" size={16} thickness={4} />
              ) : (
                <RemoveIcon />
              )
            }
            variant="contained"
          >
            {t('commons.actions.remove')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default RemovePlaceDialog;
