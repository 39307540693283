import { EmployeeService } from '@geovelo-frontends/commons';
import { Box, Toolbar } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { AppContext } from '../../app/context';
import AppDialog from '../../pages/home/app-dialog';

import CompanySizeDialog from './company-size-dialog';
import CongratsDialog from './congrats-dialog';
import Drawer, { drawerWidth } from './drawer';
import FirstMembersAddedDialog from './first-members-added-dialog';
import Header from './header';
import JobDialog from './job-dialog';
import PremiumContractDialog from './premium-contract-period-dialog';
import StandardContractDialog from './standard-contract-period-dialog';
import TrialPeriodEndSoonDialog from './trial-period-end-soon-dialog';
import WelcomeDialog from './welcome-dialog';

const now = moment();

function Layout(): JSX.Element {
  const {
    partner: {
      current: currentPartner,
      contract,
      getStartedProgression,
      firstMembersAddedDialogOpenRef,
    },
    user: { employee, home: userHome },
    actions: { setUserEmployee },
  } = useContext(AppContext);
  const [drawerOpenOnMobile, openDrawerOnMobile] = useState(false);
  const [trialPeriodEndSoonDialogOpen, openTrialPeriodEndSoonDialog] = useState<string | null>(
    null,
  );
  const [standardContractDialogOpen, openStandardContractDialog] = useState<string | null>(null);
  const [premiumContractDialogOpen, openPremiumContractDialog] = useState<string | null>(null);
  const [companySizeDialogOpen, openCompanySizeDialog] = useState(false);
  const [jobDialogOpen, openJobDialog] = useState(false);
  const [firstMembersAddedDialogOpen, openFirstMembersAddedDialog] = useState(false);
  const [congratsDialogOpen, openCongratsDialog] = useState(false);
  const [welcomeDialogOpen, openWelcomeDialog] = useState(false);
  const [appDialogOpen, openAppDialog] = useState(false);
  const adminDialogOpenRef = useRef(false);
  const userDialogOpenRef = useRef(false);

  useEffect(() => {
    if (currentPartner && contract && employee?.isAdmin) {
      const { startDateTime: _startDateTime } = contract;
      const startDateTime = _startDateTime?.format('YYYY-MM-DD') || '';
      const {
        surveyAnswers: {
          welcomeDialogSeen,
          congratsDialogSeen,
          [`trialPeriodEndSoonDialogSeen_${startDateTime}`]: trialPeriodEndSoonDialogSeen,
          [`standardContractDialogSeen_${startDateTime}`]: standardContractDialogSeen,
          [`premiumContractDialogSeen_${startDateTime}`]: premiumContractDialogSeen,
        },
      } = employee;

      if (adminDialogOpenRef.current) return;

      if (!welcomeDialogSeen && !welcomeDialogOpen) {
        adminDialogOpenRef.current = true;
        handleWelcomeDialogOpen();
      } else if (contract.contractTemplate.code === 'geovelo-entreprise-trial-period') {
        const remainingDays = contract.endDateTime
          ? contract.endDateTime.diff(now, 'days', false)
          : 100;
        if (remainingDays <= 10) {
          if (!trialPeriodEndSoonDialogSeen) {
            adminDialogOpenRef.current = true;
            openTrialPeriodEndSoonDialog(`trialPeriodEndSoonDialogSeen_${startDateTime}`);
          }
        }
      } else if (
        contract.contractTemplate.code === 'geovelo-entreprise-standard' &&
        !standardContractDialogSeen
      ) {
        adminDialogOpenRef.current = true;
        openStandardContractDialog(`standardContractDialogSeen_${startDateTime}`);
      } else if (
        contract.contractTemplate.code === 'geovelo-entreprise-premium' &&
        !premiumContractDialogSeen
      ) {
        adminDialogOpenRef.current = true;
        openPremiumContractDialog(`premiumContractDialogSeen_${startDateTime}`);
      } else if (!currentPartner.companyMaxSize && !companySizeDialogOpen) {
        adminDialogOpenRef.current = true;
        openCompanySizeDialog(true);
      } else if (!employee.surveyAnswers?.job && !jobDialogOpen) {
        adminDialogOpenRef.current = true;
        openJobDialog(true);
      } else if (
        firstMembersAddedDialogOpenRef &&
        firstMembersAddedDialogOpenRef.current &&
        !firstMembersAddedDialogOpen
      ) {
        adminDialogOpenRef.current = true;
        openFirstMembersAddedDialog(true);
      } else if (getStartedProgression?.allDone && (!congratsDialogSeen || !congratsDialogOpen)) {
        adminDialogOpenRef.current = true;
        handleCongratsDialogOpen();
      }
    }
  }, [
    currentPartner,
    employee,
    getStartedProgression,
    contract,
    welcomeDialogOpen,
    trialPeriodEndSoonDialogOpen,
    standardContractDialogOpen,
    premiumContractDialogOpen,
    companySizeDialogOpen,
    jobDialogOpen,
    firstMembersAddedDialogOpenRef?.current,
    congratsDialogOpen,
  ]);

  useEffect(() => {
    if (currentPartner && employee?.isCollaborator && userHome !== undefined) {
      if (userDialogOpenRef.current) return;

      if (!employee.surveyAnswers.welcomeDialogSeen && !welcomeDialogOpen) {
        userDialogOpenRef.current = true;
        handleWelcomeDialogOpen();
      } else if (!employee.surveyAnswers.downloadDialogSeen && !appDialogOpen) {
        userDialogOpenRef.current = true;
        handleAppDialogOpen();
      }
    }
  }, [currentPartner, employee, welcomeDialogOpen, appDialogOpen]);

  function handleDrawerToggle() {
    openDrawerOnMobile(!drawerOpenOnMobile);
  }

  async function handleWelcomeDialogOpen() {
    if (!currentPartner || !employee || employee.surveyAnswers.welcomeDialogSeen) return;

    openWelcomeDialog(true);

    try {
      const updatedEmployee = await EmployeeService.updateEmployee(currentPartner, employee.id, {
        surveyAnswers: { ...employee.surveyAnswers, welcomeDialogSeen: true },
      });

      setUserEmployee(updatedEmployee);
    } catch (err) {
      console.error(err);
    }
  }

  async function handleCongratsDialogOpen() {
    if (!currentPartner || !employee || employee.surveyAnswers.congratsDialogSeen) return;

    openCongratsDialog(true);

    try {
      const updatedEmployee = await EmployeeService.updateEmployee(currentPartner, employee.id, {
        surveyAnswers: { ...employee.surveyAnswers, congratsDialogSeen: true },
      });

      setUserEmployee(updatedEmployee);
    } catch (err) {
      console.error(err);
    }
  }

  async function handleAppDialogOpen() {
    if (!currentPartner || !employee || employee.surveyAnswers.downloadDialogSeen) return;

    openAppDialog(true);

    try {
      const updatedEmployee = await EmployeeService.updateEmployee(currentPartner, employee.id, {
        surveyAnswers: { ...employee.surveyAnswers, downloadDialogSeen: true },
      });

      setUserEmployee(updatedEmployee);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <Box display="flex" flexGrow={1} sx={{ overflowY: 'auto' }}>
        <Header handleDrawerToggle={handleDrawerToggle} />
        <Drawer openOnMobile={drawerOpenOnMobile} toggle={handleDrawerToggle} />
        <Box
          component="main"
          flexGrow={1}
          sx={{ overflowX: 'hidden', overflowY: 'auto' }}
          width={{ sm: `calc(100% - ${drawerWidth}px)` }}
        >
          <Toolbar sx={{ display: { xs: 'block', sm: 'none' } }} />
          <Outlet />
        </Box>
      </Box>
      <TrialPeriodEndSoonDialog
        onClose={() => {
          adminDialogOpenRef.current = false;
          openTrialPeriodEndSoonDialog(null);
        }}
        open={Boolean(trialPeriodEndSoonDialogOpen)}
        surveyAnswerKey={trialPeriodEndSoonDialogOpen}
      />
      <StandardContractDialog
        onClose={() => {
          adminDialogOpenRef.current = false;
          openStandardContractDialog(null);
        }}
        open={Boolean(standardContractDialogOpen)}
        surveyAnswerKey={standardContractDialogOpen}
      />
      <PremiumContractDialog
        onClose={() => {
          adminDialogOpenRef.current = false;
          openPremiumContractDialog(null);
        }}
        open={Boolean(premiumContractDialogOpen)}
        surveyAnswerKey={premiumContractDialogOpen}
      />
      <CompanySizeDialog
        onClose={() => {
          adminDialogOpenRef.current = false;
          openCompanySizeDialog(false);
        }}
        open={companySizeDialogOpen}
      />
      <JobDialog
        onClose={() => {
          adminDialogOpenRef.current = false;
          openJobDialog(false);
        }}
        open={jobDialogOpen}
      />
      <FirstMembersAddedDialog
        onClose={() => {
          adminDialogOpenRef.current = false;
          if (firstMembersAddedDialogOpenRef) firstMembersAddedDialogOpenRef.current = false;
          openFirstMembersAddedDialog(false);
        }}
        open={firstMembersAddedDialogOpen}
      />
      <CongratsDialog
        onClose={() => {
          adminDialogOpenRef.current = false;
          openCongratsDialog(false);
        }}
        open={congratsDialogOpen}
      />
      <WelcomeDialog
        onClose={() => {
          userDialogOpenRef.current = false;
          adminDialogOpenRef.current = false;
          openWelcomeDialog(false);
        }}
        open={welcomeDialogOpen}
      />
      <AppDialog
        onClose={() => {
          userDialogOpenRef.current = false;
          openAppDialog(false);
        }}
        open={appDialogOpen}
      />
    </>
  );
}

export default Layout;
