import { InfoOutlined } from '@mui/icons-material';
import { Box, SvgIcon, SvgIconProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

export function InfoCard({
  disableIcon,
  warn,
  bgColor,
  Icon: _Icon,
  iconColor,
  text,
  action,
}: {
  action?: ReactNode;
  bgColor?: string;
  disableIcon?: boolean;
  Icon?: ((props: SvgIconProps) => JSX.Element) | typeof SvgIcon;
  iconColor?: string;
  text: ReactNode;
  warn?: boolean;
}): JSX.Element {
  const Icon = _Icon || InfoOutlined;

  return (
    <Box
      alignItems="center"
      border={`1px solid ${warn ? '#BB8A1A' : iconColor || '#96E2C1'}`}
      borderRadius={4}
      display="flex"
      flexDirection="row"
      gap={2}
      padding={2}
      sx={{ backgroundColor: warn ? '#FFF5D0' : bgColor || '#E6FEF3' }}
      width="100%"
    >
      {!disableIcon && (
        <Box alignItems="center" display="flex" flexShrink={0}>
          <Icon sx={{ color: warn ? '#BB8A1A' : iconColor || '#048760' }} />
        </Box>
      )}
      <Box
        alignItems={{ xs: 'flex-start', md: 'center' }}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        flexGrow={1}
        gap={2}
      >
        <Box flexGrow={1}>
          <Typography
            color={warn ? '#BB8A1A' : iconColor || '#048760'}
            fontWeight={600}
            variant="body2"
          >
            {text}
          </Typography>
        </Box>
        {action && <Box flexShrink={0}>{action}</Box>}
      </Box>
    </Box>
  );
}
