import { InfoOutlined } from '@mui/icons-material';
import { Box, Skeleton, SvgIconProps, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';

function NewStatCard({
  bgColor,
  Icon,
  iconColor,
  value,
  label,
  infoLabel,
}: {
  bgColor: string;
  Icon: (props: SvgIconProps) => JSX.Element;
  iconColor: string;
  infoLabel?: ReactNode;
  label: ReactNode;
  value: ReactNode;
}): JSX.Element {
  return (
    <Box
      alignItems="flex-start"
      bgcolor={bgColor}
      borderRadius={4}
      display="flex"
      flexDirection="column"
      gap={2}
      padding={3}
    >
      <Icon sx={{ color: iconColor }} />
      <Box display="flex" flexDirection="column">
        <Typography fontWeight={700} variant="h5">
          {value || <Skeleton variant="text" width={100} />}
        </Typography>
        <Box alignItems="center" display="flex" gap={1}>
          <Typography variant="body2">{label}</Typography>
          {infoLabel && (
            <Tooltip title={infoLabel}>
              <InfoOutlined color="primary" fontSize="small" />
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default NewStatCard;
